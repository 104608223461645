<template>
<v-app>
    <v-app-bar
        app
        absolute
        height="100"
        style="background-color:#37CEBB"
        flat>
        <div style="margin:10px;font-size:18px;font-weight:bold;color:white">
        <v-img :src="require('@/assets/logo_2024_200x42_white.png')" width="100" /></div>
        <v-spacer></v-spacer>
        <div style="cursor: pointer;margin:10px;font-size:14px;font-weight:bold;color:white" @click="$router.push({ name: '로그인' })">로그인</div>
        <div style="cursor: pointer;margin:10px;font-size:14px;font-weight:bold;color:white">고객센터</div>
    </v-app-bar>

    <v-main app class="auth-pages">
        <fade-transition
            :duration="200"
            origin="center top"
            mode="out-in">
            <!-- your content here -->
            <v-container class="pt-6">
                <router-view></router-view>
                <!--content-footer auth v-if="!$route.meta.hideFooter"></content-footer-->
            </v-container>
        </fade-transition>
    </v-main>
</v-app>
</template>

<script>
import {
    FadeTransition
} from "vue2-transitions";
export default {
    name: "page-layout",
    components: {
        FadeTransition,
    },
    data() {
        return {
            paragraphs: "",
            tab: null,
            sheet: false,
            uid: "",
            password: "",
            bottomSheetMessage: "로그인 권한이 없습니다.",
        };
    },
    methods: {
        headerTitle() {
            switch (this.$route.name) {
                case "SignUpBasic":
                    this.paragraphs =
                        "Use these awesome forms to login or create new account in your project for free.";
                    return "Welcome!";
                default:
                    break;
            }
        }
    },
};
</script>
