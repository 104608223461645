import Vue from "vue";
import axios from "axios";

export default {
};

export const payple = {
    install(Vue) {
        Vue.prototype.$payple = {
            authenticate: async function (onSuccess, onError) {
                console.log(window.location.protocol + "//" + window.location.host);

                var url = 'https://hospital.flydoctor.kr/pay/authenticate';
                var data = {
                    referer: window.location.protocol + "//" + window.location.host
                };
                console.log('post', data);
                await axios.post(url, JSON.stringify(data), {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                    .then(async function (response) {
                        if (response.data != undefined) {
                            onSuccess(response.data.result);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        onError(error);
                    });
            },
            getPayResult: async function (paymentId, paymentDate, onSuccess, onError) {
                console.log(window.location.protocol + "//" + window.location.host);

                var url = 'https://hospital.flydoctor.kr/pay/get';
                var data = {
                    //referer: window.location.protocol + "//" + window.location.host
                    referer: 'https://hospital.doctor365.kr',
                    payment: {
                        id: paymentId,
                        date: paymentDate
                    }
                };
                console.log('post', data);
                await axios.post(url, JSON.stringify(data), {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                    .then(async function (response) {
                        if (response.data != undefined) {
                            onSuccess(response.data.result);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        onError(error);
                    });
            },
        }
    }
};

Vue.use(payple);