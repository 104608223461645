<template>
  <v-app>
    <div class="d-flex justify-start" style="width: 100%">
      <div :style="!$vuetify.breakpoint.xs ? 'min-width:210px;' : ''">
        <drawer
          :drawer="drawer"
          :sidebarColor="sidebarColor"
          :sidebarTheme="sidebarTheme"
          :items="drawerItems"
        >
        </drawer>
      </div>
      <div style="width: 100%">
        <app-bar
          background="bg-transparent"
          has-bg
          @drawer-toggle="toggleDrawer($event)"
          :toggle-active="drawer"
          :navbarFixed="navbarFixed"
        ></app-bar>
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <router-view></router-view>
        </fade-transition>
      </div>
    </div>
  </v-app>
</template>

<script>
// /* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import { FadeTransition } from "vue2-transitions";
import Drawer from "@/components/Drawer.vue";
import AppBar from "@/components/AppBar.vue";

export default {
  components: {
    FadeTransition,
    Drawer,
    AppBar,
  },
  data() {
    return {
      drawer: null,
      drawerItems: [
        {
          title: "홈",
          items: [
            {
              title: "홈화면",
              link: "/pages/dashboard/dashboard",
              icon: "fas fa-home",
              active: false,
            },
            {
              title: "회원관리",
              icon: "fas fa-hospital",
              active: false,
              items: [
                {
                  title: "일반회원",
                  link: "/pages/dashboard/users/app-users",
                  active: false,
                },
                {
                  title: "병원회원",
                  link: "/pages/dashboard/users/hospital-users",
                  active: false,
                },
                {
                  title: "약국회원",
                  link: "/pages/dashboard/users/pharmacy-users",
                  active: false,
                },
                {
                  title: "판매자회원",
                  link: "/pages/seller-users",
                },
                {
                  title: "파트너스회원",
                  link: "/pages/partners-users",
                },
                {
                  title: "푸시발송",
                  link: "/pages/dashboard/users/send-notification",
                  active: false,
                },
                {
                  title: "푸시발송리스트",
                  link: "/pages/dashboard/users/notifications",
                  active: false,
                },
              ],
            },
            {
              title: "배송관리",
              icon: "fas fa-hospital",
              active: false,
              items: [
                {
                  title: "배송관리",
                  link: "/pages/dashboard/deliveries",
                  active: false,
                },
              ],
            },
            {
              title: "정산관리",
              icon: "fas fa-hospital",
              active: false,
              items: [
                {
                  title: "정산관리",
                  link: "/pages/dashboard/settlement",
                  active: false,
                },
                {
                  title: "결제내역",
                  link: "/pages/dashboard/payment",
                  active: false,
                },
              ],
            },
            {
              title: "게시판관리",
              icon: "fas fa-hospital",
              active: false,
              items: [
                {
                  title: "고객문의",
                  link: "/pages/dashboard/boards/questions",
                  active: false,
                },
                {
                  title: "공지사항",
                  link: "/pages/dashboard/boards/notices",
                  active: false,
                },
                {
                  title: "사용방법",
                  link: "/pages/dashboard/boards/manuals",
                  active: false,
                },
              ],
            },
            {
              title: "병원관리",
              icon: "fas fa-hospital",
              active: false,
              items: [
                {
                  title: "결제내역",
                  link: "/pages/dashboard/hospitals/hospital-payments",
                  active: false,
                },
                // {
                //   title: "병원별 정산내역",
                //   link: "/pages/dashboard/hospitals/hospital-settle",
                //   active: false,
                // },
                {
                  title: "병원별 플랜관리",
                  link: "/pages/dashboard/hospitals/plans",
                  active: false,
                },
                {
                  title: "쿠폰관리자",
                  link: "/pages/dashboard/hospitals/coupon-manager",
                  active: false,
                },
                {
                  title: "병원푸시관리",
                  link: "/pages/dashboard/hospitals/hospital-notifications",
                  active: false,
                },
                {
                  title: "진료목록",
                  link: "/pages/dashboard/hospitals/hospital-clinics",
                  active: false,
                },
                {
                  title: "비급여 항목 관리",
                  link: "/pages/dashboard/hospitals/non-benefits",
                  active: false,
                },
              ],
            },
            {
              title: "약국관리",
              icon: "fas fa-hospital",
              active: false,
              items: [
                {
                  title: "결제내역",
                  link: "/pages/dashboard/pharmacys/pharmacy-payments",
                  active: false,
                },
                // {
                //   title: "약국별 정산내역",
                //   link: "/pages/dashboard/pharmacys/pharmacy-settle",
                //   active: false,
                // },
                {
                  title: "조제목록",
                  link: "/pages/dashboard/pharmacys/pharmacy-works",
                  active: false,
                },
              ],
            },
            {
              title: "팝업관리",
              icon: "fas fa-hospital",
              active: false,
              items: [
                {
                  title: "팝업 리스트",
                  link: "/pages/dashboard/popups/popup-list",
                  active: false,
                },
              ],
            },
            {
              title: "통계",
              icon: "fas fa-hospital",
              active: false,
              items: [
                {
                  title: "진료 통계",
                  link: "/pages/dashboard/statistics/visitors",
                  active: false,
                },
                {
                  title: "현재접속자현황",
                  link: "/pages/dashboard/statistics/current-visitors",
                  active: false,
                },
              ],
            },
            {
              title: "기본설정",
              icon: "fas fa-hospital",
              active: false,
              items: [
                {
                  title: "기본설정",
                  link: "/pages/dashboard/settings/default-settings",
                  active: false,
                },
                {
                  title: "플랜관리",
                  link: "/pages/dashboard/settings/hospital-plans",
                  active: false,
                },
              ],
            },
            {
              title: "비밀번호 변경",
              link: "/pages/dashboard/my-info",
              icon: "fas fa-user-cog",
              active: false,
            },
            {
              title: "단축URL관리",
              link: "/pages/dashboard/settings/short-urls",
              icon: "fas fa-link",
              active: false,
            },
          ],
        },
      ],
      showSettingsDrawer: false,
      sidebarColor: "success",
      sidebarTheme: "transparent",
      navbarFixed: false,
    };
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    toggleSettingsDrawer(value) {
      this.showSettingsDrawer = value;
    },
    updateSidebarColor(value) {
      this.sidebarColor = value;
    },
    updateSidebarTheme(value) {
      this.sidebarTheme = value;
    },
    toggleNavbarPosition(value) {
      this.navbarFixed = value;
    },
    toggleDrawer(event) {
      this.$nextTick(() => {
        this.drawer = false;
        this.$nextTick(() => {
          this.drawer = event;
        });
      });
    },
  },
  mounted() {
    /*
        if(this.$route.path == '/') {

        }*/
    this.initScrollbar();
  },
};
</script>

<style lang="scss"></style>
